<template>
  <div class="profile px-2 py-2">
    <div class="sub-container">
      <va-form ref="updatePwdForm" class="form" @validation="false">
        <div class="row">
          <div class="flex md12 xs12">
            <va-input class="" label="原密码" type="password" placeholder="请输入您当前的密码" v-model="updatePasswordVm.oldPassword" maxlength="50" :disabled="inEdit" outline :rules="[value => (value && value.length > 0) || '必填']" />
          </div>
        </div>
        <div class="row">
          <div class="flex md12 xs12">
            <va-input class="" label="新密码" type="password" placeholder="请设置您的新密码" v-model="updatePasswordVm.newPassword" maxlength="50" :disabled="inEdit" outline :rules="[
              value => (value && value.length > 0) || '必填',
              value => (value && value.length >= 6) || '密码至少6位',
              value => value !== updatePasswordVm.oldPassword || '新密码不能和当前密码相同']" />
          </div>
        </div>
        <div class="row">
          <div class="flex md12 xs12">
            <va-input class="" label="确认新密码" type="password" placeholder="请再次确认您的新密码" v-model="updatePasswordVm.repeatPassword" maxlength="50" :disabled="inEdit" outline :rules="[
              value => (value && value.length > 0) || '必填',
              value => value === updatePasswordVm.newPassword || '两次密码输入不一致']" />
          </div>
        </div>
        <div class="row">
          <div class="flex md12 xs12">
            <va-button :rounded="false" class="px-2" @click="updatePassword" :disabled="disableSubmit">提交</va-button>
          </div>
        </div>
        <div class="row">
          <div class="flex md12 xs12">
            <va-alert icon="error" border-color="#FF0000" color="#FF0000" v-if="invalidMsg !== ''">
              {{ invalidMsg }}
            </va-alert>
          </div>
        </div>
      </va-form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import axiosService from '@/services/AxiosService'

export default defineComponent({
  setup() {
    const updatePwdForm = ref<any>(null)
    const invalidMsg = ref('')
    const disableSubmit = ref(false)
    const app = getCurrentInstance()
    const updatePasswordVm = reactive({
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    })

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

    function updatePassword() {
      invalidMsg.value = ''
      if (updatePwdForm.value.validate()) {
        axiosService.post('api/users/update-password', {
          oldPassword: updatePasswordVm.oldPassword,
          newPassword: updatePasswordVm.newPassword
        }).then(res => {
          disableSubmit.value = true
          toast({ message: '密码修改成功', color: 'success', closeable: true, duration: 5000 })
        }).catch(error => {
        if (error.response && error.response.status && error.response.status === 400) {
          if (error.response.data.incorrectPassword) {
            invalidMsg.value = '当前密码校验失败，请重新输入！'
          }
        }
      })
      }
    }
  
    return {
      updatePasswordVm,
      disableSubmit,
      updatePassword,
      updatePwdForm,
      invalidMsg,
    }
  },
})
</script>
<style lang="scss" scoped>
.profile {
  min-height: 100%;
  background-color: #fff;
  .sub-container
  {
    width: 100%;
    @media (min-width: 768px) {
      width: 30rem;
    }
  }
}
</style>

import { defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import axiosService from '@/services/AxiosService'

export default defineComponent({
  setup() {
    const updatePwdForm = ref<any>(null)
    const invalidMsg = ref('')
    const disableSubmit = ref(false)
    const app = getCurrentInstance()
    const updatePasswordVm = reactive({
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    })

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

    function updatePassword() {
      invalidMsg.value = ''
      if (updatePwdForm.value.validate()) {
        axiosService.post('api/users/update-password', {
          oldPassword: updatePasswordVm.oldPassword,
          newPassword: updatePasswordVm.newPassword
        }).then(res => {
          disableSubmit.value = true
          toast({ message: '密码修改成功', color: 'success', closeable: true, duration: 5000 })
        }).catch(error => {
        if (error.response && error.response.status && error.response.status === 400) {
          if (error.response.data.incorrectPassword) {
            invalidMsg.value = '当前密码校验失败，请重新输入！'
          }
        }
      })
      }
    }
  
    return {
      updatePasswordVm,
      disableSubmit,
      updatePassword,
      updatePwdForm,
      invalidMsg,
    }
  },
})
